var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "security" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("router.ip-cameras"),
          actionButtonIcon: "fa fa-plus",
          actionButtonDesc: _vm.$t("accessory.camera.add-new")
        },
        on: {
          action: function($event) {
            return _vm.$router.push({ name: "IPCameraAdd" })
          }
        }
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "ip-cameras",
          attrs: { data: _vm.$store.getters.ipcameras, height: "500" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "icon",
              label: _vm.$t("accessory.icon"),
              "default-sort": { prop: "name", order: "ascending" },
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      staticClass: "icon-contact",
                      attrs: {
                        src: "/assets/icons/" + scope.row.icon + ".png"
                      },
                      on: { error: _vm.onImageError }
                    })
                  ]
                }
              }
            ])
          }),
          false
            ? _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: _vm.$t("accessory.id"),
                  sortable: ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: _vm.$t("accessory.name"),
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roomId",
              label: _vm.$t("accessory.room"),
              filters: _vm.roomList,
              "filter-method": _vm.filterRoom
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.roomName(scope.row.roomId)) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          false
            ? _c("el-table-column", {
                attrs: {
                  prop: "favorite",
                  label: _vm.$t("accessory.favorite"),
                  width: "80"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "center"
                              }
                            },
                            [
                              _c("i", {
                                class: scope.row.favorite
                                  ? "fa fa-star"
                                  : "far fa-star",
                                style: {
                                  color: scope.row.favorite
                                    ? "#FFC107"
                                    : "#9e9e9e"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.switchFavorite(scope.row)
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3243160318
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "right", label: _vm.$t("accessory.actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "IPCameraEdit",
                              params: { id: scope.row.id }
                            })
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-edit" })]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.removeAccessory(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt" })]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }