<template>
  <div class="security">
    <app-navigation :title="$t('router.ip-cameras')" actionButtonIcon="fa fa-plus"
      :actionButtonDesc="$t('accessory.camera.add-new')" @action="$router.push({ name: 'IPCameraAdd' })"></app-navigation>

    <el-table class="ip-cameras" :data="$store.getters.ipcameras" height="500" v-loading="loading">

      <el-table-column prop="icon" :label="$t('accessory.icon')" :default-sort="{ prop: 'name', order: 'ascending' }"
        width="100">

        <template slot-scope="scope">
          <img @error="onImageError" :src="'/assets/icons/' + scope.row.icon + '.png'" class="icon-contact">
        </template>

      </el-table-column>

      <el-table-column v-if="false" prop="id" :label="$t('accessory.id')" sortable>
      </el-table-column>

      <el-table-column prop="name" :label="$t('accessory.name')" sortable>
      </el-table-column>

      <el-table-column prop="roomId" :label="$t('accessory.room')" :filters="roomList" :filter-method="filterRoom">
        <template slot-scope="scope">
          {{ roomName(scope.row.roomId) }}
        </template>
      </el-table-column>

      <el-table-column v-if="false" prop="favorite" :label="$t('accessory.favorite')" width="80">
        <template slot-scope="scope">
          <div style="width: 100%; text-align: center">
            <i :class="scope.row.favorite ? 'fa fa-star' : 'far fa-star'"
              :style="{ color: scope.row.favorite ? '#FFC107' : '#9e9e9e' }" @click="switchFavorite(scope.row)"></i>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="right" :label="$t('accessory.actions')">

        <template slot-scope="scope">
          <span @click="$router.push({ name: 'IPCameraEdit', params: { id: scope.row.id } })"><i
              class="far fa-edit"></i></span>
          <span @click="removeAccessory(scope.row)"><i class="far fa-trash-alt"></i></span>
        </template>

      </el-table-column>

    </el-table>

  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import { className } from "@/utils/accessory-utils";
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "IPCameras",

  components: {
    'app-navigation': Navigation
  },

  data: function () {
    return {
      loading: true,
    }
  },

  computed: {
    roomList: function () {
      return this.$store.getters.rooms.map(room => {
        return { text: room.name, value: room.id }
      })
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    className,

    filterRoom(value, row) {
      return row.roomId == value
    },

    onImageError: function (e) {
      e.target.src = "/assets/icons/ic_switch_onoff.png";
    },

    roomName(roomId) {
      const room = this.$store.getters.rooms.find(room => room.id == roomId)
      return room ? room.name : roomId
    },

    switchFavorite(accessory) {
      accessory.favorite = !accessory.favorite

      const data = {
        type: this.className(accessory.category),
        properties: accessory
      }

      this.$store.dispatch('editIPCamera', data)
    },

    removeAccessory(accessory) {
      this.$confirm(this.$t('alert.message.confirm-delete'), this.$t('alert.title.warning'), {
        confirmButtonText: this.$t('alert.button.ok'),
        cancelButtonText: this.$t('alert.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('removeIPCamera', accessory.id).then(response => {
          this.$message({
            message: this.$t(response.message),
            type: 'success'
          })
        }).catch(reason => {
          this.$message({
            message: this.$t(reason),
            type: 'error'
          })
        })
      })
    },
    async fetchData() {
      try {
        await this.$store.dispatch("getIPCameras");
        await this.$store.dispatch("getAccessories");
        await this.$store.dispatch("getRooms"),
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}
</script>

<style scoped>
@import "../../style/security.css";

span i {
  font-size: large;
  padding: 10px;
  cursor: pointer;
}

span i:hover {
  background-color: #00BFD7;
  border-radius: 5px;
  color: white;
}

span i:active {
  box-shadow: 0px 0px 10px #00BFD7;
  border-radius: 5px;
}

div i {
  font-size: large;
  cursor: pointer;
}

.icon-contact {
  filter: brightness(0.45);
  width: 40px;
  height: 40px;
}
</style>